import { useEffect, useRef } from "react";
import MarlaHeadshot from "../../../assets/images/Marla.jpg";
import ChristineHeadshot from "../../../assets/images/Christine-Metz.jpg";
import GregersenHeadshot from "../../../assets/images/Gregersen_Peter.jpg";
import { gsapSplitTextAnimationv2, gsapSplitTextLineAnimation, gsapAlphaYInAnimation } from "../../gsap/common";

function Research(props) {

  const researchRef = useRef([]);
  const researchContentRef = useRef([]);
  const lujanLabRef = useRef([]);
  const metzLabRef = useRef([]);
  const peterRef = useRef([]);
  const uterPartnerLabs = useRef([]);
  researchContentRef.current = [];
  useEffect(() => {
    const ctx = props.gsap.context(() => {
        /*
            * funcation: gsapSplitTextAnimationv2
            * param: 
                *   gsap obj, 
                *   element Ref, 
                *   elementTrigger Ref, 
                *   opacity
                *   yPercent
                *   duration
                *   stagger
                *   start
                *   end
            *
        */

        gsapSplitTextAnimationv2(props.gsap, researchRef, researchRef,
            1, 110, 1.5, 0.1, "top bottom", "top bottom"
        );
       
        gsapSplitTextAnimationv2(props.gsap, uterPartnerLabs, uterPartnerLabs,
            1, 110, 1.5, 0.1, "top bottom", "top bottom"
        );

        /*  
                @param: 
                    *   gsap obj, 
                    *   element Ref, 
                    *   elementTrigger Ref, 
                    *   opacity
                    *   yPercent
                    *   duration
                *
            */

          gsapAlphaYInAnimation(props.gsap, researchContentRef, researchContentRef, 0, 30, 0.5, 1, 0, "bottom bottom", "bottom bottom");

          gsapSplitTextLineAnimation(props.gsap, lujanLabRef, lujanLabRef,
            1, 50, 0.6, "top bottom", "bottom bottom");
          
          gsapSplitTextLineAnimation(props.gsap, metzLabRef, metzLabRef,
            1, 50, 0.6, "top bottom", "bottom bottom");
          
            gsapSplitTextLineAnimation(props.gsap, peterRef, peterRef,
            1, 50, 0.6, "top bottom", "bottom bottom");
          
            // gsapSplitTextLineAnimation(props.gsap, commingSoonContentRef, commingSoonRef,
            // 1, 120, 0.6, "top 80%", "top bottom");
                  
    });
    return () => ctx.revert();
}, []);

  const _researchContentRef = el => {
    if (el && !researchContentRef.current.includes(el)) {
        researchContentRef.current.push(el);
    }
  };

  return (
    <>
      <section className="research spacer text-white">
        <div className="container container-sm relative">
          <div className="row">
            <div className="col-md-10">
              <h2 ref={researchRef} className="">Research</h2>
              <p ref={_researchContentRef} className="h4 primary-text-color">
                Without research, we’re stuck with different flavors of birth
                control
              </p>
            </div>
          </div>
          <div className="row mt-5 spacer-bottom">
            <div className="col-md-10">
              {/*<p ref={_researchContentRef} className="heading-font-24 mb-2">10% of each membership</p>*/}
              <p ref={_researchContentRef} className="mb-0">
                UterineKind donates to our partner laboratories researching the most
                pressing questions about the female system and the root causes
                of conditions like endometriosis, fibroids, adenomyosis and
                PCOS.
              </p>
            </div>
          </div>
          <div className="row research_blk">
            <div ref={lujanLabRef} className="col-md-11">
              <h3 className="h4 mb-4">UterineKind Partner Labs</h3>
              <p className="h4 primary-text-color">Lujan Lab</p>
              <p className="h5 fw-bold">
                at Cornell University, Division of Nutritional Sciences, College
                of Agriculture and Life Sciences
              </p>
              <p>
                The Lujan Laboratory investigates the link between nutrition,
                metabolism and reproductive physiology in females, with
                particular expertise in Polycystic Ovarian Syndrome
              </p>
              <div className="row mt-4">
                <div className="col-sm-4 mb-3 mb-sm-0">
                  <img src={MarlaHeadshot} alt="Marla Lujan" />
                </div>
                <div className="col-sm-8">
                <span className="heading-font-24 d-block mb-2">Marla Lujan, Ph.D.</span>
                  <p>
                    Principal Investigator and Associate Professor of Human
                    Nutrition
                  </p>
                  <p>
                    Education: Ph.D. (Physiology), M.Sc. (Physiology), B.Sc.
                    (Life Sciences), Queen’s University</p>
                  <p>Research Interests:
                    Polycystic Ovary Syndrome (PCOS), Folliculogenesis,
                    Menstrual Cycle, Nutrition and Metabolism, Ultrasonography
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div ref={metzLabRef} className="row research_blk spacer-sm">
            <div className="col-md-11">
              <p className="h4 primary-text-color">Metz-Gregersen Lab</p>
              <p>
                at The Feinstein Institutes for Medical Research at Northwell
                Health
              </p>
              <div className="row mt-4">
                <div className="col-sm-4 mb-3 mb-sm-0">
                  <img src={ChristineHeadshot} alt="Christine Metz" />
                </div>
                <div className="col-sm-8">
                <span className="heading-font-24 d-block mb-2">Christine Metz, Ph.D.</span>
                  <p>
                    Professor, Institute of Molecular Medicine, Feinstein
                    Institutes for Medical Research
                  </p>
                  <p>
                    Co-Director of the ROSE Study (Research Outsmarts
                    Endometriosis)
                  </p>
                  <p>
                    Associate Dean, Elmezzi Graduate School of Molecular
                    Medicine Professor, Departments of Obstetrics & Gynecology
                    and Molecular Medicine, Donald and Barbara Zucker School of
                    Medicine at Hofstra/Northwell</p>
                    <p>Director, Faculty Affairs,
                    Feinstein Institutes for Medical Research
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div ref={peterRef} className="row research_blk">
            <div className="col-md-11">
              <div className="row">
                <div className="col-sm-4 mb-3 mb-sm-0">
                  <img src={GregersenHeadshot} alt="Peter Gregersen" />
                </div>
                <div className="col-sm-8">
                  <span className="heading-font-24 d-block mb-2">Peter Gregersen, MD</span>
                  <p>
                  Professor, Institute of Molecular Medicine, Feinstein Institutes for Medical Research</p>
                  <p>Co-Director of the ROSE Study</p>
                  <p>Professor, Molecular Medicine, Donald and Barbara Zucker School of Medicine at Hofstra/Northwell
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </section>
    </>
  );
}

export default Research;
