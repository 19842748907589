import React, { useState } from "react";
import './Header.css';
import logo from '../../../images/images/logo.png'
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Sidetab } from '@typeform/embed-react'
import { HashLink as HashLink } from 'react-router-hash-link';
import {
    Link
} from 'react-router-dom';

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";



function Header(props) {
    const [activeMenu, setActiveMenu] = useState(null);
    const [videoText, setVideoText] = useState("Explainer Video");
    const { width } = useWindowDimensions();

    const handleMenu = () => {
        let mobMenu = document.querySelector(".navbar-toggler");
        var _xyzname = document.getElementById("navBarId").classList;

        if (_xyzname.contains('toggle-menu')) {
            setTimeout(function () {
                document.getElementById("navbarNav").classList.remove('show');
            }, 215);
        }
        mobMenu.classList.toggle("toggle-menu");
    }

    function collapseMenu(cls) {
        setActiveMenu(cls);
        // if (width < 992) {
        if (true) {
            if (document.getElementById("navbarNav").classList.contains("show")) {
                document.getElementById("navBarId").click();
            }
        }
    }

    return <>
        <header>
            <nav className="navbar py-0 h-100 testingInDevelopBranch" id="">
                <div className="container container-sm  position-relative h-100">
                    <Link onClick={(even5) => collapseMenu('homepage')} className="navbar-brand" to="/">
                        <img src={logo} alt="logo UterineKind" width="200" height="auto" />
                    </Link>
                    <div className="desktop__link ms-auto d-none d-lg-block">
                        <Link onClick={(even5) => collapseMenu('explainer')}
                            className={`nav-item ${activeMenu === 'explainer' ? 'active' : ''}`}
                            to="/care-program"><span>Care program</span></Link>
                        <Link onClick={(even5) => collapseMenu('explainer')}
                            className={`nav-item ${activeMenu === 'explainer' ? 'active' : ''}`}
                            to="/explainer-video"><span>App intro video</span></Link>
                        <Link onClick={(even6) => collapseMenu('how-it-works')}
                            className={`nav-item ${activeMenu === 'how-it-works' ? 'active' : ''}`}
                            to="/how-it-works"><span>How app works</span></Link>
                    </div>
                    <a href="https://app.uterinekind.com" className="btn call-action-btn me-3">App Login / Join</a>
                    <button className="navbar-toggler" type="button" id="navBarId" data-bs-toggle="collapse"
                        data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation" onClick={handleMenu}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto align-items-center">
                            <li className="nav-item">
                                <Link onClick={(even5) => collapseMenu('care-program')}
                                    className={`nav-item ${activeMenu === 'care-program' ? 'active' : ''}`}
                                    to="/care-program"><span>Care Navigation Program</span></Link>
                            </li>
                            <li className="nav-item d-block d-lg-none">
                                <Link onClick={(even5) => collapseMenu('explainer')}
                                    className={`nav-item ${activeMenu === 'explainer' ? 'active' : ''}`}
                                    to="/intro-video"><span>App Intro Video</span></Link>
                            </li>
                            <li className="nav-item">
                                <HashLink onClick={(even5) => collapseMenu('faqcp')}
                                    className={`nav-item ${activeMenu === 'faqcp' ? 'active' : ''}`}
                                    to="/care-program#faqs"><span>FAQs: Care Program</span></HashLink>
                            </li>
                            <li className="nav-item">
                                <Link onClick={(even5) => collapseMenu('faq')}
                                    className={`nav-item ${activeMenu === 'faq' ? 'active' : ''}`}
                                    to="/faq"><span>FAQs: App</span></Link>
                            </li>
                            <li className="nav-item">
                                <Link onClick={(even5) => collapseMenu('about')}
                                    className={`nav-item ${activeMenu === 'about' ? 'active' : ''}`}
                                    to="/about"><span>About</span></Link>
                            </li>
                            <li className="nav-item d-block d-lg-none">
                                <Link onClick={(even6) => collapseMenu('how-it-works')}
                                    className={`nav-item ${activeMenu === 'how-it-works' ? 'active' : ''}`}
                                    to="/how-it-works"><span>How app works</span></Link>
                            </li>


                            {/*<li className="nav-item">*/}
                            {/*    <Link onClick={(even6) => collapseMenu('pricing')}*/}
                            {/*          className={`nav-item ${activeMenu === 'pricing' ? 'active' : ''}`}*/}
                            {/*          to="/pricing"><span>Pricing</span></Link>*/}
                            {/*</li>*/}

                            <li className="nav-item">
                                <Link onClick={(even6) => collapseMenu('podcast')}
                                    className={`nav-item ${activeMenu === 'podcast' ? 'active' : ''}`}
                                    to="/podcast"><span>Hello Uterus Podcast</span></Link>
                            </li>

                            {/* <li className="nav-item">
                                <Link onClick={(even6) => collapseMenu('news')}
                                      className={`nav-item ${activeMenu === 'news' ? 'active' : ''}`}
                                      to="/news"><span>News</span></Link>
                            </li> */}

                            <li className="nav-item">
                                <Link onClick={(even6) => collapseMenu('privacy-policy')}
                                    className={`nav-item ${activeMenu === 'privacy-policy' ? 'active' : ''}`}
                                    to="/privacy-policy"><span>Privacy Policy</span></Link>
                            </li>

                            <li className="nav-item last-item d-block d-sm-none">
                                <a className="nav-link btn rounded-0" href="https://app.uterinekind.com">App Login or
                                    Join</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/*<Sidetab id="lQews8gy" buttonText="OHIO chemical disaster"/>*/}
        </header>
    </>
}

export default Header;
