import React, { useEffect, useRef } from "react";
import { gsapSplitTextAnimationv2, gsapSplitTextLineAnimation, gsapAlphaYInAnimation, gsapScrollToElementById } from "../../gsap/common";
function CommentDoesntMean(props){
    const commonRef = useRef();
    const symptomAssocRef = useRef();

    const animateAlphaYRef = useRef([]);
    const meanTextRef = useRef([]);
    animateAlphaYRef.current = [];


    useEffect(() => {
        const ctx = props.gsap.context(() => {

            gsapSplitTextAnimationv2(props.gsap, commonRef, commonRef,
                1, 100, 1.5, 0.1, "bottom 90%", "top bottom"
            );

            gsapSplitTextLineAnimation(props.gsap, symptomAssocRef, symptomAssocRef,
                1, 80, 1, "top 90%", "top bottom");

            gsapSplitTextLineAnimation(props.gsap, meanTextRef, meanTextRef,
                1, 30, 1, "bottom bottom", "top bottom");

            gsapAlphaYInAnimation(props.gsap, animateAlphaYRef, animateAlphaYRef, 0, 30, 0.5, 1, 0, "top 70%", "bottom bottom");
        });

        return () => ctx.revert();
    }, []);


    const _animateAlphaYRef = el => {
        if (el && !animateAlphaYRef.current.includes(el)) {
            animateAlphaYRef.current.push(el);
        }
    };

    const handleClick = (elementId) => {
        gsapScrollToElementById(props.gsap, elementId, 1, -40)
    }
    return(
        <section id="commntDoesntMean" className="text-center spacer">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 mx-auto px-4">
                        <h2 ref={commonRef} className="animate-heading">Common doesn’t mean normal </h2>
                        <p ref={symptomAssocRef} className="h4 my-5">Symptoms associated with “bad periods” are also symptoms of these common, chronic conditions that impact those born with a uterus</p>
                        <div className="mean-list">
                            <span ref={_animateAlphaYRef} className="h4">AUB (Abnormal Uterine Bleeding)</span>
                            <span ref={_animateAlphaYRef} className="h4">Endometriosis</span>
                            <span ref={_animateAlphaYRef} className="h4">Fibroids</span>
                            <span ref={_animateAlphaYRef} className="h4">Adenomyosis</span>
                            <span ref={_animateAlphaYRef} className="h4">PCOS (Polycystic Ovary Syndrome)</span>
                            <span ref={_animateAlphaYRef} className="h4">PID (Pelvic Inflammatory Disease)</span>
                            <span ref={_animateAlphaYRef} className="h4">Chronic pelvic pain</span>
                            <span ref={_animateAlphaYRef} className="h4">Uterine prolapse</span>
                        </div>

                        <div ref={meanTextRef} className="mean-text">
                            <p>U by UterineKind features these conditions, which affect millions of people who live with debilitating symptoms, yet can struggle for years to get diagnosed and treated. While hormonal treatments like birth control may help manage some symptoms, it’s important to take action and obtain a definitive diagnosis. It’s easier to advocate for your needs, research your options, and find a supportive community when you know exactly what’s causing your symptoms.</p>
                        </div>    
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CommentDoesntMean;