import React, { useEffect, useRef } from 'react';
import ExternalLink from "../../other/ExternalLink";

import './css/HomePage.css';
import './css/care-program.css';

function CareProgram(props) {
    return (
        <>
            <section className="home-hero hero home content-grid home-care-program spacer text-center">
                <div className="inner-wrapper">
                    <div className="container">
                        <div className="row">
                            <h1 className="mb-0">The UterineKind Care Program</h1>
                            <h2 className="mb-5">Is your period a nightmare?</h2>
                            <h3 className="mb-4">Introducing a kinder, effective care program for "bad periods"</h3>
                            <p className="mb-5">Get educated, diagnosed and treated in days, not years, supported by Board Certified Patient Advocates and our vetted network of physician specialists.</p>
                            <a href="/care-program" class="btn mt-5">Learn About the Program</a>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CareProgram;