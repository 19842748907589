import React, { useEffect, useRef } from 'react';

import './css/care-program.css';
import price_icon from '../../../images/images/price-icon.svg';
import gyn_icon from '../../../images/images/gyn-icon.png';
import app_icon from '../../../images/images/app-icon.png';
import advocate_icon from '../../../images/images/advocate-icon.png';
import { gsapTitleInXAnimation, gsapTitleInYAnimation } from "../../gsap/common";

import animation_1 from '../../../images/images/how-can-there-be-this-much-blood.svg';
import animation_2 from '../../../images/images/my-mom-says-her-periods-where-like-this-too.svg';
import animation_3 from '../../../images/images/doctors-think-im-faking.svg';
import animation_4 from '../../../images/images/i-get-really-stressed-at-school.svg';
import animation_5 from '../../../images/images/im-sick-of-doctor-appointments-that-go-nowhere.svg';
import animation_6 from '../../../images/images/my-docter-says-im-just-unlucky.svg';

import { Link } from 'react-router-dom';
import { SuperSEO } from 'react-super-seo';

import gsap from "gsap";
import { ScrollTrigger, ScrollToPlugin } from "gsap/all";
import { HashLink as HashLink } from 'react-router-hash-link';




function CareProgram() {

    const anim1Ref = useRef();
    const anim2Ref = useRef();
    const anim3Ref = useRef();
    const anim4Ref = useRef();
    const anim5Ref = useRef();
    const anim6Ref = useRef();

    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);


    useEffect(() => {

        const ctx = gsap.context(() => {
            const timeline = gsap.timeline({
            });

            timeline
                .add(gsapTitleInYAnimation(gsap, anim2Ref, "power1.out", 0.15, 0, 0, 1, 0.1), "+=0.15")
                .add(gsapTitleInYAnimation(gsap, anim5Ref, "power1.out", 0.15, 0, 0, 1, 0.1), "0.15")
                .add(gsapTitleInYAnimation(gsap, anim4Ref, "power1.out", 0.15, 0, 0, 1, 0.1), "+=0.15")
                .add(gsapTitleInYAnimation(gsap, anim6Ref, "power1.out", 0.15, 0, 0, 1, 0.1), "+=0.15")
                .to([anim1Ref.current, anim3Ref.current, anim5Ref.current], {
                    opacity: 0,
                    duration: 2,
                    ease: "power1.out",
                    onComplete: () => {
                        anim1Ref.current.style.display = 'none';
                        anim3Ref.current.style.display = 'none';
                        anim5Ref.current.style.display = 'none';
                    }
                }, "+=2.5")
                .to([anim2Ref.current, anim4Ref.current, anim6Ref.current], {
                    opacity: 0,
                    duration: 2,
                    ease: "power1.out",
                    onComplete: () => {
                        anim2Ref.current.style.display = 'none';
                        anim4Ref.current.style.display = 'none';
                        anim6Ref.current.style.display = 'none';
                    }
                }, "<")
                .to('.animations', {
                    opacity: 0,
                    duration: 2,
                    ease: "power1.out",
                    onComplete: () => {
                        document.querySelector('.animations').style.display = 'none';
                    }
                }, "-=2");


            const floatingElements = document.querySelectorAll('.ball-floating');
            floatingElements.forEach((element, index) => {
                gsap.to(element, {
                    y: -20 + index * 4,
                    repeat: -1,
                    yoyo: true,
                    ease: 'power1.inOut',
                    duration: 2 + index * 0.2
                });
            });


        });
        return () => ctx.revert();
    }, []);

    const handleSmoothScroll = (e) => {
        e.preventDefault();

        gsap.to(window, {
            scrollTo: {
                y: "#ceo-in-100-days",
                offsetY: 70,
            },
            duration: 1.5,
            ease: "power2.out",
        });
    };


    return (
        <>
            <SuperSEO title="UterineKind™ - Care Program" />
            <section className="care-program spacer-both">
                <div className='animations'>
                    <div ref={anim1Ref} className="animation-item">
                        <img src={animation_1} alt="How can there be this much blood" />
                    </div>
                    <div ref={anim2Ref} className="animation-item">
                        <img src={animation_2} alt="My mom says her periods were like this too" />
                    </div>
                    <div ref={anim3Ref} className="animation-item">
                        <img src={animation_3} alt="Doctors think I'm faking" />
                    </div>
                    <div ref={anim4Ref} className="animation-item">
                        <img src={animation_4} alt="I get really stressed at school" />
                    </div>
                    <div ref={anim5Ref} className="animation-item">
                        <img src={animation_5} alt="I'm sick of doctor appointments that go nowhere" />
                    </div>
                    <div ref={anim6Ref} className="animation-item">
                        <img src={animation_6} alt="My doctor says I'm just unlucky" />
                    </div>

                </div>
                <div className="ball-floating dot-y-1"></div>
                <div className="ball-floating dot-p-1"></div>
                <div className="ball-floating dot-b-1"></div>
                <div className="ball-floating dot-p-2-sp"></div>
                <div className="ball-floating dot-w-1"></div>
                <div className="ball-floating dot-y-2"></div>
                <div className="ball-floating dot-p-3"></div>
                <div className="ball-floating dot-w-2"></div>
                <div className="ball-floating dot-p-4"></div>
                <div className="ball-floating dot-b-2"></div>
                <div className="ball-floating dot-y-3"></div>
                <div className="ball-floating dot-p-5"></div>
                <div className="ball-floating dot-w-3"></div>
                <div className="ball-floating dot-y-4"></div>
                <div className="ball-floating dot-w-4"></div>
                <div className="inner-wrapper">
                    <div className="container container-sm">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='h3 mb-4'>This changes now</h1>
                                <h2 className='mb-5'>Get the support you need for a formal diagnosis and confidently choose the best care options to meet your goals.</h2>
                                <p className='pt-4'>See how our Board Certified Patient Advocates can fast-track your access to care and help you confidently manage your health.</p>
                                <a
                                    href="#ceo-in-100-days"
                                    className="btn mt-2"
                                    onClick={(e) => handleSmoothScroll(e)}
                                >
                                    About the Program
                                </a>

                                <h3 className='mt-5 pt-5 mb-5'>What is a normal period?</h3>
                                <p>Periods that DON'T disrupt your life and periods that DO disrupt your life are not the same. But often that's how they're treated.</p>
                                <p>There are normal periods: a day or two of cramps, maybe a craving, a bit blue. And then there's endometriosis, fibroids, adenomyosis, PCOS, PMDD, Von Willebrand Disease…even celiac disease.</p>
                                <p>Symptoms of these common, complex conditions are not normal and are often dismissed.</p>
                                <p>Patients and physicians struggle to connect the dots, leading to delays in diagnosis and treatment. This can go on for years.</p>
                                <p>Meanwhile, conditions progress, symptoms are normalized, and quality of life plummets.</p>

                                <h4 className='mt-5 mb-5 py-4'>We do things differently here. Because we've been there.</h4>
                                <p>Book your free 10-minute consultation with a Board-Certified Patient Advocate to learn more about our program.</p>
                                <a href="https://calendly.com/team_uterinekind/meet-a-board-certified-patient-advocate" target='_blank' className="btn mt-2 mb-5">Book a call now</a>

                                <h3 className='my-5 pt-5' id='ceo-in-100-days'>Be the CEO of your <br />body in 100 days</h3>
                                <p>How do we get you diagnosed in days, not years, without nonsense & trauma?</p>
                                <h4 className='mt-5'>Board Certified Patient Advocates (BCPAs)</h4><p>A trusted, experienced resource and guide who knows what this struggle is like</p>
                                <p className='standout'>-BCPAs eliminate barriers to care through <strong>education, care navigation and emotional support</strong>, which improves patient outcomes<sup>[1]</sup></p>

                                <h4 className='mt-5'>Record Your Data</h4>
                                <p>Build your own safe and private symptom record with our proprietary platform</p>
                                <p className='standout pb-3'>-90% of a diagnosis comes from your symptoms–how you present them matters<sup>[2]</sup></p>

                                <h4 className='mt-5'>Care Navigation</h4>
                                <p>Take the right steps at the right time and with the right physicians</p>
                                <p className='standout mt-2 pb-5'>-Not all obstetricians & gynecologists (OBGYNs) are trained to treat these complex conditions and often a team of doctors is needed<sup>[3]</sup></p>

                                <p>With the support of a Board Certified Patient Advocate, and our proprietary symptom monitoring and education platform, you'll have the tools and guidance needed to navigate the healthcare system on your terms.</p>
                                <a href="https://buy.stripe.com/eVaaEUfKy7CX3p63ch" target='_blank' className="btn mt-2 mb-5">Unlock better care</a>


                                <h3 className='my-5 pt-5'>How it Works</h3>
                                <div className='care-steps'>
                                    <p className='signup-step'>1. Sign up</p>
                                    <p>Register, pay and schedule your Advocate consultation</p>
                                    <p className='icon mt-5'><img src={price_icon} /></p>

                                    <p className='signup-step pt-5'>2. Meet Your Advocate</p>
                                    <p>Your (human!) partner for the next 100 days </p>
                                    <p className='icon mt-5'><img src={advocate_icon} /></p>

                                    <p className='signup-step pt-5'>3. Download our UterineKind App</p>
                                    <p>Proprietary tool designed to accelerate a formal diagnosis</p>
                                    <p className='icon mt-5'><img src={app_icon} /></p>

                                    <p className='signup-step pt-5'>4. Physician Consultation</p>
                                    <p>Referral to vetted specialty physician, virtual or in-person</p>
                                    <p className='icon mt-5'><img src={gyn_icon} /></p>
                                    <p className='cta'><a href="https://buy.stripe.com/eVaaEUfKy7CX3p63ch" className="btn mt-5 mb-5">Get started now</a></p>
                                </div>

                                <div className='care-steps in-hundred-days mb-5'>
                                    <h3 className='my-5 pt-5 text-center'>100 days with UterineKind care
                                    </h3>
                                    <p className='fw-semibold'>In 100 days you will:</p>
                                    <ul className='mb-5'>
                                        <li>Build an accurate, detailed symptom report for appointments</li>
                                        <li>Learn about the conditions that may be causing symptoms</li>
                                        <li>Understand the diagnostic process and treatment options</li>
                                        <li>Master advocating for your needs in doctor appointments</li>
                                        <li>Know the different types of female health physicians</li>
                                        <li>Be referred to vetted physicians who will listen and take action</li>
                                        <li>Be in control of your options</li>
                                        <li>Confidently make decisions that are best for you</li>
                                    </ul>
                                    <p className='fw-semibold'>Plus:</p>
                                    <ul className='mb-5'>
                                        <li>1 year of unlimited access to the UterineKind app</li>
                                        <li>Own your medical record data</li>
                                        <li>2 additional check-in calls with your BCPA</li>
                                        <li>Weekly check-ins via chat with your BCPA</li>
                                        <li>Unlimited questions via chat</li>
                                        <li>Safe, secure, HIPAA-compliant communications platform</li>
                                        <li>1-swipe to delete your data</li>
                                    </ul>

                                    <p className='fw-semibold'>Our data-first, advocate-led program eliminates patients' biggest issues:</p>
                                    <ul class="issue-list">
                                        <li data-icon="🕒">Too-short appointments</li>
                                        <li data-icon="📅">Too many appointments</li>
                                        <li data-icon="🔍">Can't find the right doctor</li>
                                        <li data-icon="😑">Doctors don't listen</li>
                                        <li data-icon="😵‍💫">Complicated health system</li>
                                        <li data-icon="🚫">No education on conditions/treatments</li>
                                        <li data-icon="❌">Birth control to fix EVERYTHING…even when it can't</li>
                                    </ul>

                                </div>

                                <p className='pt-5'><strong>Ready?</strong><br />Become the CEO of your body in 100 days&mdash;avoid nonsense, save on unnecessary costs, and make confident, informed decisions&mdash;with support from your Board Certified Patient Advocate.</p>
                                <a href="https://buy.stripe.com/eVaaEUfKy7CX3p63ch" target='_blank' className="btn mt-2 mb-5">Sign me up!</a>
                                <p><strong>Unsure?</strong><br />Sign up for a free 10-minute call with a Board Certified Patient Advocate to have your questions answered.</p>
                                <a href="https://calendly.com/team_uterinekind/meet-a-board-certified-patient-advocate" target='_blank' className="btn mt-2 mb-5">Sign up for a free 10-minute call</a>

                            </div>
                        </div>
                        <div id='faqs' className='row mt-5'>
                            <div className='col-md-12 faq faq-item-spacer'>
                                <div className="accordion" id="accordionCare">
                                    <h3 className='my-5 pt-5 text-left'>FAQs</h3>


                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                                <div className="faq__label"><span className="d-block">
                                                    Who is it for?
                                                </span>

                                                </div>
                                            </button>
                                            <div id="collapseTwo" className="accordion-collapse collapse"
                                                aria-labelledby="headingTwo" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p>Our care navigation services, provided by Board Certified Patient Advocates (BCPAs), are specifically designed for adolescent/teen girls and adult women who feel their symptoms are being dismissed or not taken seriously. Whether you're struggling to be heard, believed, or supported, we're here to remove the barriers to your best life.</p>
                                                    <p>Our mission is to enable early intervention, especially for adolescents/teens, to ensure people receive the care and support they need to thrive in life, even when living with chronic conditions.</p>
                                                    <p>If you've been told your symptoms are "just bad periods" or brushed off as "normal," our BCPA care navigation services can help you get the compassionate, effective, and personalized care you deserve.</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree">

                                                <div className="faq__label"><span className="d-block">Do I need a diagnosis?
                                                </span>

                                                </div>
                                            </button>
                                            <div id="collapseThree" className="accordion-collapse collapse"
                                                aria-labelledby="headingThree" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p>Yes, a formal diagnosis is crucial. Symptoms like painful periods, heavy bleeding, irregular cycles, chronic pelvic pain, bloating, constipation, and bladder issues often signal an underlying condition such as endometriosis, PCOS, fibroids, PMDD, or adenomyosis. These conditions can take years to diagnose, during which time symptoms may worsen and the condition may progress.</p>
                                                    <p>Having an accurate diagnosis is essential because it allows you to focus on a specific, known cause rather than guessing at potential issues. A clear diagnosis leads to more effective treatment, better management of symptoms, and ultimately improved outcomes. Treating an unknown can prolong discomfort and uncertainty, but when you know what you're dealing with, you can confidently make choices that serve your goals.</p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingeight">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseeight"
                                                aria-expanded="false" aria-controls="collapseeight">

                                                <div className="faq__label"><span
                                                    className="d-block">What is included?
                                                </span>
                                                </div>
                                            </button>
                                            <div id="collapseeight" className="accordion-collapse collapse"
                                                aria-labelledby="headingeight" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p>When you join our program, you're stepping into a comprehensive, empowering approach to your health—one that's focused, personalized, and designed to help you achieve lasting results. </p>
                                                    <p>Here's what's included to help you become the CEO of your body in just 100 days:</p>
                                                    <ul>
                                                        <li>
                                                            <strong>Board Certified Patient Advocates (BCPAs)</strong><br />
                                                            You'll be supported by trusted, experienced advocates who understand what you're going through. Our BCPAs eliminate the common barriers to care, guiding you through education, emotional support, and care navigation. This approach has been shown to improve patient outcomes by ensuring you get the right help from the right doctors at the right time.
                                                        </li>
                                                        <li>
                                                            <strong>Data-first consultations</strong><br />
                                                            Using our proprietary platform, you'll build your own private, secure symptom record. Why is this important? Because 90% of a diagnosis comes from how you present your symptoms—and how you track them matters. With our system, you'll have all the details organized and ready to share with healthcare providers. Let your data do your talking and focus the physician on diagnosing.
                                                        </li>
                                                        <li>
                                                            <strong>Care Navigation</strong><br />
                                                            Getting the right care at the right time is key. Not all OBGYNs are equipped to treat complex conditions like endometriosis, PCOS, or fibroids. Our BCPAs will assist in locating and vetting physicians for your specific needs, ensuring that you see the most qualified professionals experienced in treating your condition.
                                                        </li>
                                                    </ul>
                                                    <p>In just 100 days, you'll be ready to take control of your health and move towards an accurate diagnosis and treatment plan. No guesswork, no delays—just results, without trauma or added costs.</p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingten">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseten"
                                                aria-expanded="false" aria-controls="collapseten">

                                                <div className="faq__label"><span
                                                    className="d-block">What is not included?
                                                </span>


                                                </div>
                                            </button>

                                            <div id="collapseten" className="accordion-collapse collapse"
                                                aria-labelledby="headingten" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p>UterineKind BCPAs do not diagnose, prescribe medications, or treat conditions. That work is left in the hands of the most capable: the doctors we vet on your behalf. See “What is included?” to explore what it's like to work with a UterineKind BCPA.</p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingtwenty">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapsetwenty"
                                                aria-expanded="false" aria-controls="collapsetwenty">

                                                <div className="faq__label"><span className="d-block">Why UterineKind?
                                                </span>


                                                </div>
                                            </button>
                                            <div id="collapsetwenty" className="accordion-collapse collapse"
                                                aria-labelledby="headingtwenty" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p>We're mission-driven to enable early intervention for adolescent and adult chronic female health conditions that often take years to diagnose. These delays are caused by:</p>
                                                    <ul>
                                                        <li>Symptom dismissal</li>
                                                        <li>Difficulty accessing the right care or the right doctor</li>
                                                        <li>Taboo topics and the normalization of painful or disruptive experiences</li>
                                                        <li>Poor patient-physician communication</li>
                                                        <li>Lack of access to accurate, easy-to-understand medical information</li>
                                                    </ul>
                                                    <p>We empower you to become the CEO of your own body—equipping you with the tools, data, knowledge, and support to advocate confidently for your health needs, with us by your side.</p>
                                                    <p>We aim to decrease the number of appointments needed to receive a formal diagnosis and care plan by guiding you to specialist physicians who meet our high standards.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingFour">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                                aria-expanded="false" aria-controls="collapseFour">

                                                <div className="faq__label"><span className="d-block">What is a UterineKind BCPA?
                                                </span>


                                                </div>
                                            </button>
                                            <div id="collapseFour" className="accordion-collapse collapse"
                                                aria-labelledby="headingFour" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p><strong>A UterineKind Board Certified Patient Advocate (BCPA)</strong> is trained to support those living with diagnosed or undiagnosed common, chronic conditions such as <strong>endometriosis, fibroids, PCOS, PMDD</strong>, and <strong>adenomyosis</strong>. They help you improve your care experience by:</p>
                                                    <ul>
                                                        <li>Educating you on conditions, your diagnosis, and treatment options</li>
                                                        <li>Facilitating communication with your healthcare team</li>
                                                        <li>Guiding you through the healthcare system & vetting physicians on your behalf</li>
                                                        <li>Offering emotional support and guiding you to medically-reviewed resources</li>
                                                        <li>Ensuring your rights are respected</li>
                                                        <li>Helping you make informed decisions</li>
                                                    </ul>
                                                    <p>With a UterineKind patient advocate, you get expert support, guidance, and a compassionate partner every step of the way.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingFive">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                                aria-expanded="false" aria-controls="collapseFive">

                                                <div className="faq__label"><span className="d-block">Is my data safe?
                                                </span>
                                                    <div id="collapseFive" className="accordion-collapse collapse"
                                                        aria-labelledby="headingFive" data-bs-parent="#accordionCare">
                                                        <div className="accordion-body">
                                                            <p>Yes. The UterineKind platform is HIPAA-compliant, as is our patient communications platform. For over ten years we've built and supported multiple digital patient-facing platforms in highly regulated environments with no adverse events.</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </button>
                                        </div>

                                    </div>

                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingsix">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapsesix"
                                                aria-expanded="false" aria-controls="collapsesix">

                                                <div className="faq__label"><span className="d-block">Do you employ doctors?
                                                </span>


                                                </div>
                                            </button>
                                            <div id="collapsesix" className="accordion-collapse collapse"
                                                aria-labelledby="headingsix" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p>We don't employ the doctors we vet on your behalf. We do source and vet up to 3 physicians best suited to diagnose and treat your symptoms and support you through diagnosis and treatment, helping you and your chosen physician achieve excellent outcomes.</p>
                                                    <p>Our Chief Medical Officer, Dr. Ashley Davis is board-certified in Obstetrics and Gynecology. She holds a Focused Practice Designation in Pediatric and Adolescent Gynecology from the American Board of Obstetrics and Gynecology. Dr. Davis specializes in minimally invasive surgery, endometriosis excision surgery, and fibroid removal.</p>
                                                    <p>Our platform was designed with patients and <HashLink to="/about#physicians">physicians</HashLink>, including Dr. John Petrozza and Dr. Evelyn Nicole Mitchell</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingeleven">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseheadingeleven"
                                                aria-expanded="false" aria-controls="collapseheadingeleven">

                                                <div className="faq__label"><span className="d-block">Why should I pay cash when I have insurance?
                                                </span>


                                                </div>
                                            </button>

                                            <div id="collapseheadingeleven" className="accordion-collapse collapse"
                                                aria-labelledby="headingheadingeleven" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p>Even if you have health insurance, paying for healthcare navigation services can offer several important benefits:</p>
                                                    <ol>
                                                        <li>
                                                            <strong>Avoid Insurance Limitations</strong><br />
                                                            Health insurance plans often have restrictions on services, coverage, or provider choices. Paying for care navigation services can give you more flexibility in finding the best care options for your needs.
                                                        </li>
                                                        <li>
                                                            <strong>Expert Guidance Without Insurance Interference</strong><br />
                                                            Insurance protocols don't constrain BCPA care navigators, so they can provide unbiased advice, helping you find the right doctors, diagnostics and treatments, without the influence of insurance company rules.
                                                        </li>
                                                        <li>
                                                            <strong>Maximize Insurance Benefits</strong><br />
                                                            BCPA care navigators can help you understand and make the most of your insurance benefits, saving you money by finding in-network specialists, uncovering overlooked benefits, and reducing the number of appointments required to achieve your goals.
                                                        </li>
                                                        <li>
                                                            <strong>Privacy and Advocacy</strong><br />
                                                            Since cash-based care navigators work directly for you, they can advocate for your best interests without the influence of insurers, ensuring you're getting the care you deserve.
                                                        </li>
                                                        <li>
                                                            <strong>Cost Savings on Uninsured Services</strong><br />
                                                            Navigators can guide you to lower-cost providers and treatment options, particularly for services that might not be covered by insurance, reducing your out-of-pocket expenses.
                                                        </li>
                                                        <li>
                                                            <strong>Enhanced Support for Complex Claims</strong><br />
                                                            Navigators can assist with complicated insurance claims, billing issues, and denials, saving you time and frustration in resolving these matters.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingseven">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseseven"
                                                aria-expanded="false" aria-controls="collapseseven">

                                                <div className="faq__label"><span className="d-block">How will the UterineKind care navigation program save me money?
                                                </span>


                                                </div>
                                            </button>
                                            <div id="collapseseven" className="accordion-collapse collapse"
                                                aria-labelledby="headingseven" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p>Our Board Certified Patient Advocate (BCPA) services can be a powerful tool for saving money in several ways:</p>
                                                    <ol>
                                                        <li>
                                                            <strong>Finding Discounted Cash-Pay Rates</strong><br />
                                                            Many diagnostic services, like lab tests, imaging (MRIs, X-rays), and screenings, offer discounted rates for patients who pay directly without involving insurance. BCPAs can help you identify these cash-pay options, which are often more affordable than going through insurance due to high deductibles or co-pays.
                                                        </li>
                                                        <li>
                                                            <strong>Identifying Lower-Cost Providers</strong><br />
                                                            BCPAs have insider knowledge of where to find the most cost-effective diagnostic services. They can help you compare prices across different providers, ensuring you get the best deal without sacrificing quality, especially when you're paying out-of-pocket.
                                                        </li>
                                                        <li>
                                                            <strong>Maximizing Insurance Coverage for Diagnostics</strong><br />
                                                            If you're using insurance, BCPAs can help you understand which diagnostic tests are covered under your plan, and ensure you use in-network facilities to reduce costs. They can also guide you through any paperwork or pre-authorization requirements, helping you avoid surprise charges or denied claims.
                                                        </li>
                                                        <li>
                                                            <strong>Negotiating with Providers</strong><br />
                                                            In cases where diagnostics aren't covered by insurance or you're facing high out-of-pocket costs, healthcare navigators can sometimes negotiate on your behalf to lower the cost, set up payment plans, or find alternative, more affordable testing options.
                                                        </li>
                                                        <li>
                                                            <strong>Helping You Access Uninsured or Underinsured Services</strong><br />
                                                            For people without adequate insurance, or those with high-deductible plans, healthcare navigators can help find free or low-cost diagnostic services. They're often aware of community health programs, charitable resources, or diagnostic centers offering affordable rates.
                                                        </li>
                                                    </ol>
                                                    <p>The UterineKind BCPA-led program helps you make informed decisions, find cost-effective diagnostics and treatments, decrease the number of doctor appointments needed to get diagnosed, and avoid unnecessary expenses, ultimately saving money while getting the care you need.</p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingtwelve">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapsetwelve"
                                                aria-expanded="false" aria-controls="collapsetwelve">

                                                <div className="faq__label"><span className="d-block">Is it covered by insurance/HSA/FSA?
                                                </span>


                                                </div>
                                            </button>

                                            <div id="collapsetwelve" className="accordion-collapse collapse"
                                                aria-labelledby="headingtwelve" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p>The UterineKind BCPA-led program is eligible for reimbursement per HSA/FSA guidelines. Healthcare navigation services for chronic conditions may be eligible for FSA/HSA reimbursement if deemed medically necessary. These services—such as care coordination and treatment guidance—can qualify if they are part of managing a medical condition. To submit a claim, you'll need an itemized invoice and, in some cases, a <strong>Letter of Medical Necessity</strong> from your provider. Always check with your FSA/HSA administrator for specific guidelines and documentation requirements before submitting a claim. Your BCPA is here to assist you with this process.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="accordion-item">
                                        <div className="accordion-header" id="heading14">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapse14"
                                                aria-expanded="false" aria-controls="collapse14">

                                                <div className="faq__label"><span className="d-block">Are diagnostic tests included?
                                                </span>


                                                </div>
                                            </button>

                                            <div id="collapse14" className="accordion-collapse collapse"
                                                aria-labelledby="heading14" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p>Tests your physician orders are not included in the UterineKind BCPA program. But we can help you identify the most cost-effective path depending on the tests your physician recommends. Often, you can save money on imaging based on location of services provided. Or if you pay cash rather than obtain diagnostics through your insurance.</p>
                                                    <p>BCPAs will help you identify opportunities to save money based on your needs and goals.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="heading15">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapse15"
                                                aria-expanded="false" aria-controls="collapse15">

                                                <div className="faq__label"><span className="d-block">What if I need surgery?

                                                </span>


                                                </div>
                                            </button>

                                            <div id="collapse15" className="accordion-collapse collapse"
                                                aria-labelledby="heading15" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p>For over 10 years, the team at UterineKind has worked with specialty surgeons who are trained experts in complex and minimally invasive gyencologic surgery. We are the team to work with to identify the right surgeon based on your unique circumstances and goals.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="heading16">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapse16"
                                                aria-expanded="false" aria-controls="collapse16">

                                                <div className="faq__label"><span className="d-block">Can I purchase the program as a gift?

                                                </span>


                                                </div>
                                            </button>

                                            <div id="collapse16" className="accordion-collapse collapse"
                                                aria-labelledby="heading14" data-bs-parent="#accordionCare">
                                                <div className="accordion-body">
                                                    <p>Yes. If you are purchasing as a gift, complete the purchase, and you may either schedule the gift recipient's BCPA one-hour consultation at that time or email hello @ uterinekind.com for gift support.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className="col-md-12 footnotes">
                                <p>References:</p>
                                <p>[1] Natale-Pereira A, Enard KR, Nevarez L, Jones LA. The role of patient navigators in eliminating health disparities. Cancer. 2011;117(15 Suppl):3543-3552. doi:10.1002/cncr.26264 </p>
                                <p>[2] Peterson MC, Holbrook JH, Von Hales D, Smith NL, Staker LV. Contributions of the history, physical examination, and laboratory investigation in making medical diagnoses. West J Med. 1992;156(2):163-165.</p>
                                <p>[3] Rani S, Sehgal A, Pandher DK, et al. Specialists’ View on Segregating Obstetrics and Gynecology. J South Asian Feder Obst Gynae 2020;12(6):387–390.</p>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CareProgram;
