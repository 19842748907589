export function FirstTimeUseContent(){
    return[
        {
            id:"10011",
            key:1001,
            title:"Signup with your first name and email"
        },
        {
            id:"10012",
            key:1002,
            title:"Check your email for a message from UterineKind. Click on the link in the email to verify your email address and to set your password"
        },
        {
            id:"10013",
            key:1004,
            title:"Login with your email and password"
        },
        {
            id:"10015",
            key:1005,
            title:"Start entering your symptoms data"
        }
    ]
}