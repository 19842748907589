import { useEffect} from "react";
import gsap from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import { SuperSEO } from "react-super-seo";
import { PopupButton } from "@typeform/embed-react";
import { Widget } from '@typeform/embed-react';

function OhioDerailmentPage(props) {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  useEffect(() => {
    gsap.to(window, {
      scrollTo: {
        y: ".pageHeader",
      },
      duration: 0,
    });
  }, []);

  useEffect(() => {
    const ctx = gsap.context(() => {});

    return () => ctx.revert();
  }, []);

  return (
    <>
        <SuperSEO title="UterineKind™ - UterineKind to offer free symptom tracking for East Palestine, Ohio residents" />
        <section className="hero-shadow hero-section-spacer spacer-bottom">
            <div className="container container-sm position-relative">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h1 className="h3">
                            UterineKind to offer free symptom tracking for East Palestine, Ohio residents
                        </h1>
                        <Widget 
                          id="lQews8gy" style={{ height: '650px', marginTop: '50px' }} className="ukform-ohio-help"
                        />                        
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default OhioDerailmentPage;
