import { useEffect, React } from 'react';
import gsap from 'gsap';
import { TextPlugin } from "gsap/all";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import './css/HowItWorks.css';
import HowToUse from './HowToUse';
import HowToRecord from './HowToRecord';
import CheckTheBox from './CheckTheBox';
import YourData from './YourData';
import HowToUseChart from './HowToUseChart';
import FourSectionOfApp from './FourSectionOfApp';
import FirstTimeUse from './FirstTimeUse';
import { SuperSEO } from 'react-super-seo';

function HowItWorks() {
    gsap.registerPlugin(TextPlugin)
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin)
    useEffect(() => {
        gsap.to(window, {
            scrollTo: {
                y: ".pageHeader",
            },
            duration: 0,
        });
    }, []);

    return <>
        <SuperSEO title="UterineKind™ - How it works" />
        <SuperSEO description="Navigate through the 'How It Works' page on UterineKind to understand the simple steps to start using our app. This guide provides clear instructions and tips to help you effortlessly track and manage your uterine health symptoms for enhanced well-being." />
        <HowToUse gsap={gsap} />
        <HowToRecord gsap={gsap} />
        <CheckTheBox gsap={gsap} />
        <YourData gsap={gsap}/>
        <HowToUseChart gsap={gsap} />
        <FourSectionOfApp gsap={gsap} />
        <FirstTimeUse gsap={gsap} />
    </>
}

export default HowItWorks
