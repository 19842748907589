import { useEffect, useRef } from "react";
import CarolHeadshot from "../../../assets/images/Carol-pic.png"
import { Link } from "react-router-dom";
import { gsapSplitTextLineAnimation, gsapAlphaYInAnimation } from "../../gsap/common";

function Founder(props) {
  const withoutresearchRef = useRef([]);
  const founcerRef = useRef([]);
  founcerRef.current = [];

  useEffect(() => {
    const ctx = props.gsap.context(() => {
      
      gsapSplitTextLineAnimation(props.gsap, withoutresearchRef, withoutresearchRef,
        1, 50, 0.6, "top bottom", "bottom bottom");

      // gsapSplitTextLineAnimation(props.gsap, peterRef, peterRef,
      // 1, 50, 0.6, "top bottom", "bottom bottom");

      gsapAlphaYInAnimation(props.gsap, founcerRef, founcerRef, 0, 30, 0.5, 0.4, 0, "bottom bottom", "bottom bottom");

    });
    return () => ctx.revert();
  }, []);

  
  const _founcerRef = el => {
    if (el && !founcerRef.current.includes(el)) {
      founcerRef.current.push(el);
    }
  };

  return (
    <>
      <section className="founder spacer pb-0 text-center">
        <div className="container">
          <div className="row">
            <div  className="col-md-9 mx-auto">
              <p ref={withoutresearchRef} className="h4 fw-bold">
                “Today, delayed diagnoses cause great harm to everyone: patients, providers, health systems and society.
                Our app helps patients and providers arrive at a diagnosis fast & achieve shared decision-making.
                This saves lives, resources and time.”
              </p>
              <img ref={_founcerRef} src={CarolHeadshot} alt="Carol" className="founder-headshot" />
              <h4 ref={_founcerRef} className="heading-font-24">Carol Johnson</h4>
              <p ref={_founcerRef}>Founder & CEO, UterineKind<br />Host, Hello Uterus</p>
              <div ref={_founcerRef} class="read-more my-4">
                
                {/* <a class="text-purple news-readmore" href="javascript:void(0)">
                  MORE FROM CAROL<span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path fill="#fff" d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" /></svg></span>
                </a> */}
                <Link class="text-purple news-readmore" to="/founder">
                  MORE FROM CAROL
                  <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path fill="#fff" d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" /></svg></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Founder;
