import { useEffect, useRef } from 'react';
import { ExpoScaleEase } from 'gsap/all';
import bigUlogo from '../../../assets/images/header-scroll-logo-1.svg'
import { gsapSplitTextAnimationv2 } from '../../gsap/common/';
import { gsapTitleInXAnimation, gsapTitleInYAnimation, gsapAlphaFadeInAnimation, gsapScrollToElementById } from '../../gsap/common'

function ContentHero(props) {
    const badPeriodRef = useRef();
    const chronicConditionsRef = useRef();
    const areUndiagnosedRef = useRef();
    const heroSubHeadingRef = useRef();
    const logoAnimateRef = useRef();
    const herologoAnimateRef = useRef();
    const herotaglineAnimateRef = useRef();
    const animatePartTool11Ref = useRef([]);
    const animatePartTool12Ref = useRef([]);
    useEffect(() => {
        
    
        
        const ctx = props.gsap.context(() => {

            /*
                *   function: gsapTitleInXAnimation, gsapTitleInYAnimation
                *   @param: gsap obj, element Ref, ease, duration, opacity, x/y, delay
                * ------------------
                *   function: gsapAlphaFadeInAnimation
                *   @param: gsap obj, element Ref, autoAlpha value, ease, delay
            */
            gsapTitleInXAnimation(props.gsap, badPeriodRef, "power1.out", 1, 0, -120, null, .5);
            gsapTitleInXAnimation(props.gsap, areUndiagnosedRef, "power1.out", 1, 0, -350, null, .5);
            gsapTitleInXAnimation(props.gsap, chronicConditionsRef, "power1.out", 1, 0, -350, null, .5);
            gsapAlphaFadeInAnimation(props.gsap, heroSubHeadingRef, 0, "power1.out", 1.2);
            gsapAlphaFadeInAnimation(props.gsap, herologoAnimateRef, 0, "power1.out", 1.6);
            gsapAlphaFadeInAnimation(props.gsap, herotaglineAnimateRef, 0, "power1.out", 1.6);
            gsapTitleInYAnimation(props.gsap, logoAnimateRef, "power1.out", null, 0, 200, 1.5);

            
            
            props.gsap.set(".introducing", {
                autoAlpha: 0
            });
            props.gsap.fromTo("#Line_427", {
                opacity: 1
            }, {
                y: 45,
                opacity: 0,
                duration: 1,
                ease: "none",
                repeat: -1
            });

            // let bigLetterU = props.gsap.timeline({
            //     scrollTrigger: {
            //         trigger: "#big-letter-u",
            //         pin: true,
            //         markers: false,
            //         start: "top top",
            //         end: "bottom 40%",
            //         anticipatePin: 1,
            //         pinSpacing: false,
            //         yoyo: true,
            //         scrub: 1,
            //     }
            // });

            // bigLetterU.to(".logo-animate svg", {
            //     scale: 20,
            //     transformOrigin: "50% 100%",
            //     autoAlpha: 1,
            //     duration: 2,
            //     force3D: false,
            //     ease: ExpoScaleEase.config(0.1, 20),
            // });

            // bigLetterU.to(".logo-animate #U-logo-Center", {
            //     fill: "#944FBA"
            // }, "-=2");
            // bigLetterU.to(".introducing", {
            //     autoAlpha: 1,
            //     display: "block"
            // });

        });

        gsapSplitTextAnimationv2(props.gsap, animatePartTool11Ref, animatePartTool11Ref,
            1, 100, 1.5, 0.1, "bottom bottom", "bottom top");

            gsapSplitTextAnimationv2(props.gsap, animatePartTool12Ref, animatePartTool12Ref,
                1, 100, 1.5, 0.1, "bottom bottom", "bottom top", .5);
        return () => ctx.revert();
    }, []);

    const handleClick = (elementId) => {
        gsapScrollToElementById(props.gsap, elementId, 1)
    }

    return <>
        <div>
            <section id="big-letter-u" className="hero home-hero flex-column">
                <div className="container container-sm position-relative">
                    <div className="row">
                        <div className="col-md-12 text-white text-center">
                            {/* <h1 className="d-flex flex-column mb-4 text-white">
                                <span className="h2 hero__heading__one" ref={badPeriodRef}>Symptoms are evidence,</span>
                                <span className="h2 hero__heading__three" ref={chronicConditionsRef}>your doctor the detective.</span>
                            </h1> */}
                            <h1 className="h2"><span className='d-block' ref={animatePartTool11Ref}>Solve the mystery of</span><span className='d-block' ref={animatePartTool12Ref}> your “bad periods”</span></h1>
                            <p className='h4 mt-3 fw-bold' ref={herotaglineAnimateRef}>with the U by UterineKind app</p>
                            <img src={bigUlogo} className="mt-4 hero__logo" ref={herologoAnimateRef}  alt="logo" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
}
export default ContentHero;