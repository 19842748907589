import {React, useEffect, useRef} from "react";
import gsap from "gsap";

import {ScrollTrigger} from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import Slider from "react-slick";
import Newsone from "../../../../assets/images/news-1.jpg";
import Pressthumb from '../../../../assets/images/press-article.png';
import "../css/index.css";
import Newsheadshot from "../../../../assets/images/John-Petrozza.jpg";
import Newsthree from "../../../../assets/images/news-3.jpg";
import {useNavigate} from "react-router-dom";

function NewsDetailsPage() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3.5,
        slidesToScroll: 3,
        centerMode: false,
        // initialSlide:1,
        //centerPadding:"200px",
        arrows: false,
        afterChange: () =>
            document
                .querySelector(".more-news__slider .slick-list")
                .classList.add("slick-padding"),
    };
    let navigate = useNavigate();

    function redirectToNewsPage() {
        let path = `/news`;
        navigate(path);
    }

    return (
        <>

            <section className="hero-shadow hero-section-spacer spacer-bottom  purple-shadow news-detail">
                <div className="container container-sm position-relative">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <a onClick={redirectToNewsPage} href={() => false} className='btn back-btn mb-5'
                               style={{cursor: "pointer"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z"/>
                                    <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"
                                          fill="rgba(255,255,255,1)"/>
                                </svg>
                                Back
                            </a>
                            <div className="row pb-5">
                                <div className="col-md-12">
                                    <div className="news-info mb-2">
                                        <span className="ps-0">Article</span>
                                        <span className="fw-normal">June 15, 2023</span>
                                    </div>
                                    <h2 className="h4 primary-text-color">Groundbreaking Study Reveals Link Between Oral
                                        Contraception Use and Depression</h2>
                                    <p>
                                        We’d like to preface this summary saying: We are PRO-birth control. But we are
                                        also pro-research.</p>

                                    <p>Oral contraceptives (OC) have been the most widely used and studied drugs of all
                                        time. The topic of hormonal birth control and its potential impact on mental
                                        health is <strong>complex</strong> and <strong>varies from person to
                                            person</strong>.</p>

                                    <p>While some don’t experience negative effects from using BC, some may notice
                                        changes in mood, emotions, and overall mental health.</p>

                                    <p>It's important to note that not everyone will have the same experience with
                                        birth control, because surpriiiise — we’re all unique. Symptoms can differ
                                        depending on the specific type of birth control and individual’s physiology and
                                        mental health history.</p>

                                    <p>That being said, <a
                                        href="https://www.cambridge.org/core/journals/epidemiology-and-psychiatric-sciences/article/populationbased-cohort-study-of-oral-contraceptive-use-and-risk-of-depression/B3C611DD318D7DC536B4BD439343A5BD"
                                        target="_blank" rel="noreferrer"
                                        className="text-decoration-underline hover-color">this
                                        new research publication</a> adds to previous research proving
                                        the link between mental health issues and oral contraceptive use. This
                                        particular study was large and identifies OC symptoms and side effects that
                                        patients have been complaining about for ages.</p>

                                    <p>Even now, patients are told that their OC could not be causing anxiety or
                                        depression. Thanks to this large study, those patients are having their symptoms
                                        validated.</p>

                                    <p>This is one reason why we designed the UterineKind app — to help patients
                                        validate their experience through self-reported data so they and their care team
                                        can easily measure and monitor their symptoms, including mental health, as they
                                        navigate the complexities of gynecologic care.</p>

                                    <p>Until research catches up, this patient-generated medical record is essential
                                        when advocating for your needs while accessing care.

                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p>The new study titled, "Population-based cohort study of oral contraceptive use
                                        and risk of depression" concludes that taking birth control pills can increase
                                        the likelihood of depression, especially shortly after starting on the pill. And
                                        those who started taking BC in their teens have a 130% greater risk of
                                        depression.</p>

                                    <p>While more research is needed to find out why and how birth control pills can
                                        cause depression, this study proves that it’s not all in our heads.</p>

                                    <p>Published in the journal Epidemiology and Psychiatric Sciences, researchers
                                        looked at data from over 260,000 women from the UK Biobank, a large-scale
                                        biomedical database and research resource.</p>

                                    <p><strong>They found that individuals who used OCs (oral contraceptives) were more
                                        likely
                                        to be diagnosed with depression than those who did not.</strong></p>

                                    <p>The risk of depression was highest in the first year of taking OCs. After that,
                                        the risk went down, but it was still higher than individuals who did not take
                                        OCs.</p>

                                    <p>The researchers also found that the risk of depression was higher for women who
                                        had a family history of depression.
                                    </p>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>What else do you need to know about this study?</h4>
                                    <ul>
                                        <li>OC use during teen years might increase the risk of depression later in
                                            life, even after stopping the pill
                                        </li>
                                        <li>Teenage users showed a 130% higher incidence of depression symptoms,
                                            compared to a 92% increase in adult users.
                                        </li>
                                        <li>The sample size was large, which makes the results more reliable</li>
                                        <li>The study included limitations that should be considered. However, the study
                                            is still valuable since it provides one of the largest and most
                                            comprehensive studies of the link between OC and depression to date. Notable
                                            limitations:
                                            <ul>
                                                <li>Sample selection bias — the UK Biobank is a population-based study,
                                                    but is not
                                                    representative of the general population of the UK, including
                                                    healthier and more
                                                    white individuals than the general population
                                                </li>
                                                <li>Lack of data on the type of BC used</li>
                                                <li>Incomplete data on the BC use — the study only includes information
                                                    on age at first
                                                    and last use of BC, not accounting for those who may have
                                                    stopped/started using
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <h4>So, what does this mean if I take oral contraceptives?</h4>
                                    <ul>
                                        <li>Monitor signs and symptoms of depression. As soon as possible, start using
                                            the free
                                            UterineKind app to record mental health and other symptoms. If you begin to
                                            experience any of the following symptoms, speak to your doctor with
                                            specific,
                                            self-reported data:
                                            <ul>
                                                <li>Feelings of sadness or hopelessness</li>
                                                <li>Loss of interest in activities you used to enjoy</li>
                                                <li>Changes in appetite or weight</li>
                                                <li>Trouble sleeping or sleeping too much</li>
                                                <li>Loss of energy</li>
                                                <li>Difficulty concentrating</li>
                                                <li>Thoughts of death or suicide</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <h4>Our thoughts on this study:</h4>
                                    <p>Our bodies are unique and not all people respond to
                                        birth control in the same way. It’s important to focus on how your mind and your
                                        body respond, rather than comparing your experience to your friends' experiences
                                        with their particular hormonal treatment.</p>

                                    <p>Your experience is unique to you. Monitor your data. And if someone says it's
                                        all in your head, this study proves otherwise. Birth control pills can increase
                                        the risk of depression.</p>

                                    <p>With early intervention and more awareness of the possible symptoms of birth
                                        control, people will be more informed and speak up. The importance of
                                        recognizing the symptoms and involving your care team (gynecologist, therapist,
                                        etc) early is critical. As always, we are here to help you inform and support
                                        you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            {/* <section className="more-news spacer-bottom">
        <div className="container">
      <div className="row">
            <div className="col-md-12">
              <h2 className="h4">More news</h2>
            </div>
          </div>
          </div>
        <div className="more-news__slider">
        <div className="container-fluid g-0 mt-5">
          <div className="container">
          <div className="row g-0">
            <div className="col-md-12">
            <Slider {...settings}>
             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">1 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">2 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">3 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">4 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">5 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">6 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">7 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">8 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">9 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">10 News title goes here and this one is pretty long</a></h3>
             </div>
             
               
         </Slider>
         </div>
         </div>
            </div>
          </div>
        </div>
      </section> */}
        </>
    );
}

export default NewsDetailsPage;
