import React, {useEffect, useRef} from 'react'
import {gsapSplitTextAnimationv2} from '../../gsap/common';
import {Player, BigPlayButton} from 'video-react';
import thumbnail from '../../../assets/images/Introvideo-thumbnail.jpg'
import './video.css';
import VideoPlayer from "./VideoPlayer";

import Applestore from '../../../../src/images/apple-store.png';
import Playstore from '../../../../src/images/google-play.svg';

function IntroVideo(props) {
    return (

        <section className="intro pt-4 pb-4 text-center" id="intro">
            <div className="container container-sm spacer-top">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <h2 className="h4 animate-heading font-weight-300">Learn about UterineKind in 75 seconds</h2>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-9 mx-auto">
                        <VideoPlayer/>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default IntroVideo;