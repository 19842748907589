import { useEffect, useRef } from "react";

import Bodyicon from '../../../assets/images/my-body-icon.svg';
import Painicon from '../../../assets/images/Symptom-swirl Pain-orange.svg';
import Mentalicon from '../../../assets/images/Symptom-swirl-MH.svg';
import Bicon from '../../../assets/images/Symptom swirl Body-icon.svg';
import Sleepicon from '../../../assets/images/Symptom-swirl Sleep.svg';
import Energyicon from '../../../assets/images/Symptom-swirl Enegy.svg';
import Bleedicon from '../../../assets/images/Symptom-swirl-Bleed.svg';
import { gsapTitleInXAnimation, gsapTitleInYAnimation } from "../../gsap/common";


function HowToUse(props) {

    const howToUseRef = useRef();
    const openTheAppRef = useRef();
    const myBodyRef = useRef();
    const inFewMinutesRef = useRef();

    useEffect(() => {
        const ctx = props.gsap.context(() => {  

            /*
                *   function: gsapTitleInXAnimation, gsapTitleInYAnimation
                *   @param: gsap obj, element Ref, ease, duration, opacity, x/y, delay
                * ------------------
                *   function: gsapAlphaFadeInAnimation
                *   @param: gsap obj, element Ref, autoAlpha value, ease, delay
            */
            gsapTitleInXAnimation(props.gsap, howToUseRef, "power1.out", 0.7, 0, -200, null, .5);
            gsapTitleInYAnimation(props.gsap, openTheAppRef, "power1.out", 0.7, 0, 40, 0.5);
            gsapTitleInYAnimation(props.gsap, myBodyRef, "power1.out", 0.7, 0, 40, 1);
            gsapTitleInYAnimation(props.gsap, inFewMinutesRef, "power1.out", 0.7, 0, 20, 1.5);
            
            const symtomsImageTimeline  = props.gsap.timeline({ delay: 1.7 })    
            const symtomsTitleTimeline  = props.gsap.timeline({ delay: 1.7 })    
            symtomsTitleTimeline
            .from(".textPellow", { autoAlpha: 0,
                y: "20px",
                ease: "Power4.easeOut",
                duration: 0.5,
                // delay: 1.7
            })
            .from(".textPreen", { autoAlpha: 0,
                y: "20px",
                ease: "Power4.easeOut",
                duration: 0.5,
                // delay: 0.5
            })
            .from(".textPlue", { autoAlpha: 0,
                y: "20px",
                ease: "Power4.easeOut",
                duration: 0.5,
                // delay: 0.5
            })
            .from(".textPurplee", { autoAlpha: 0,
                y: "20px",
                ease: "Power4.easeOut",
                duration: 0.5,
                // delay: 0.5
            })
            .from(".textPink", { autoAlpha: 0,
                y: "20px",
                ease: "Power4.easeOut",
                duration: 0.5,
                // delay: 0.5
            })
            .from(".textPed", { autoAlpha: 0,
                y: "20px",
                ease: "Power4.easeOut",
                duration: 0.5,
                // delay: 0.5
            });

            symtomsImageTimeline
                .from(".clsPaincon", { duration: .5,
                    autoAlpha: 0,
                    y: "20px",
                    rotation: -240,
                    transformOrigin: "center",
                    ease: "Power4.easeOut",
                    // delay: 1.1
                })
                .from(".clsMentalicon", { duration: .5,
                    autoAlpha: 0,
                    y: "20px",
                    rotation: -240,
                    transformOrigin: "center",
                    ease: "Power4.easeOut",
                    // delay: 0.5
                })
                .from(".clsBicon", { duration: .5,
                    autoAlpha: 0,
                    y: "20px",
                    rotation: -240,
                    transformOrigin: "center",
                    ease: "Power4.easeOut",
                    // delay: 0.5
                })
                .from(".clsSleepicon", { duration: .5,
                    autoAlpha: 0,
                    y: "20px",
                    rotation: -240,
                    transformOrigin: "center",
                    ease: "Power4.easeOut",
                    // delay: 0.5
                })
                .from(".clsEnergyicon", { duration: .5,
                    autoAlpha: 0,
                    y: "20px",
                    rotation: -240,
                    transformOrigin: "center",
                    ease: "Power4.easeOut",
                    // delay: 0.5
                })
                .from(".clsBleedicon", { duration: .5,
                    autoAlpha: 0,
                    y: "20px",
                    rotation: -240,
                    transformOrigin: "center",
                    ease: "Power4.easeOut",
                    // delay: 0.5
                });
            
            // symtomsImageTimeline.to(".clsMentalicon", { rotation: -360, duration: 0.8, ease: "power1.out" })
            // symtomsTitleTimeline.to(".text-green", { rotation: -360, duration: 0.8, ease: "power1.out"})
        });
        return () => ctx.revert();
    }, []);

    return <>
        <section className="works spacer-both text-center hero-shadow">
            <div className="container container-sm">
                <div className="row">
                    <div className='col-md-12'>
                        <h1 ref={howToUseRef} className='h3'>How to use the UterineKind app?</h1>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className='col-lg-6 mx-auto'>
                        <span ref={openTheAppRef} className='h4 d-block'>Open the app on your device.</span>
                        <div ref={myBodyRef} className='h4 my-4 fw-bold text-white'>
                            <span className="d-block d-sm-inline">You’ll begin on</span> <img src={Bodyicon} alt='Bodyicon' className="ms-3" /> 
                            <span className='primary-text-color'>My Body</span>
                        </div>
                        <p ref={inFewMinutesRef}>In a few minutes each day easily record detailed symptoms in six categories:</p>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className='col-md-8 mx-auto'>
                        <div className='symptomes-categories'>
                            <div className='symptomes-categories__blk'>
                                <span className='text-yellow textPellow'>pain</span>
                                <img className="clsPaincon" src={Painicon} alt='pain' />
                            </div>
                            <div className='symptomes-categories__blk'>
                                <span className='text-green textPreen'>Mental Health</span>
                                <img className="clsMentalicon" src={Mentalicon} alt='Mental Health' />
                            </div>

                            <div className='symptomes-categories__blk'>
                                <span className='text-blue textPlue'>Body</span>
                                <img className="clsBicon" src={Bicon} alt='Body' />
                            </div>

                            <div className='symptomes-categories__blk'>
                                <span className='text-purplee textPurplee'>Sleep</span>
                                <img className="clsSleepicon" src={Sleepicon} alt='Sleep' />
                            </div>

                            <div className='symptomes-categories__blk'>
                                <span className='text-pink textPink'>Energy</span>
                                <img className="clsEnergyicon" src={Energyicon} alt='Energy' />
                            </div>

                            <div className='symptomes-categories__blk'>
                                <span className='text-red textPed'>Bleeding</span>
                                <img className="clsBleedicon" src={Bleedicon} alt='Bleeding' />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </>
}
export default HowToUse;