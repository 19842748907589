import { Link } from "react-router-dom";
import Carolsignature from "../../../assets/images/Carol-Sig-rev.svg"
function AboutFounder() {
  return (
    <>
      <section className="hero-shadow testimonials spacer hero-section-spacer testimonials-shadow founder-bio">
      {/* <section className="founder-bio"> */}
        <div className="container container-sm position-relative">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div class="read-more mt-4">
                <Link to='/about' className="text-purple news-readmore">
                <span className="ms-0 me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 25 25"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="#fff"
                        d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z"
                      />
                    </svg>
                  </span>
                  BACK TO ABOUT PAGE
                </Link>
                {/* <a class="text-purple news-readmore" href="javascript:void(0)">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 25 25"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="#fff"
                        d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z"
                      />
                    </svg>
                  </span>{" "}
                  BACK TO ABOUT PAGE
                </a> */}
              </div>
              <div className="founder-bio__content mt-4">
                <p>
                  Staring back at doctors who say the symptoms are normal,
                  bleeding through clothes in public, losing big chunks of time
                  to pain, and living with undiagnosed conditions for
                  years–these are experiences I know well. Are they familiar to
                  you?</p>

                  <p>These experiences are common for millions of people - some
                  will endure life-altering complications because their
                  conditions were not diagnosed quickly. Mental health suffers,
                  relationships suffer, and they are left to battle against
                  debilitating conditions while navigating misinformation and
                  bias inside a stressed healthcare system.</p>
                  
                  <p>We saw a way to help.</p>
                  <p> I didn’t hear the word ‘fibroid’ until I was 41 and
                  finally pregnant…not once during the 40+ appointments
                  scheduled to get help with my “horrible periods”. Despite
                  having symptoms that lined up with the chronic conditions I
                  have since learned about.</p>
                  <p> I had the same experience with
                  undiagnosed celiac disease. For 20 years I rubbed steroid
                  cream on my body. The itchy, symmetrical rash was biopsied and
                  white-coat shoulders shrugged in too many consultations to
                  count.</p>
                  <p>After diagnosing it myself through research and an
                  elimination diet, and living rash-free for 5 years (there is
                  no cure for celiac disease), my new primary care doctor rolled
                  his eyes at me.</p>
                  <p>“You diagnosed it?”</p>
                  <p>He wasn’t really asking me as much as he was mocking me.</p>
                  <p> “Yep,” I said, fuming inside, showing nothing on the outside.</p>
                  <p>That was the last time I went to a primary care doctor. Not a good move on my part, but
                  gaslighting leaves a mark. He is credited for inspiring me to
                  learn about how my body works so I can be a better caretaker
                  of it.</p>
                  <p> And I learned an important lesson about research:
                  doctors can only do so much if there is little to no research
                  to guide them.</p>
                  <p> It was clear that I had to take control of my
                  healthcare experience. Be the steward of my body. Understand
                  how it operates and direct its care. I couldn’t (and
                  shouldn’t) rely upon physicians to do it.</p>
                  <p>Throughout my career, I’ve found myself drawn to healthcare, with the last
                  ten years spent focused on those seeking care for chronic
                  conditions that impact women, trans and non-binary people. As
                  the founder of Advocats Agency, a creative agency focused on
                  healthcare, we worked for some of the best medical device
                  manufacturers in the business creating educational content for
                  patients and healthcare professionals.</p>
                  <p>But every time we
                  interviewed patients (100s of patients), the problems they
                  needed fixed remained. In January of 2022, we shifted our
                  focus to solving those problems and created UterineKind.</p>
                  <p>
                  Our team is united behind our mission: improving uterine care
                  for everyone.</p>
                  <p> If your quality of life is suffering because of
                  symptoms that are not being adequately diagnosed and
                  addressed, UterineKind can help.</p>
                  <p> We’re glad you’re here.</p>
                    
                  <img src={Carolsignature} className="mt-2 mb-4"/>
                  <p className="mb-0">PS: Have a story to share, a question, or just need to be heard? Email me</p>
                  <p><a href="mailto:carolj@uterinekind.com" className="text-purple">carolj@uterinekind.com</a></p>  

                   <div class="read-more back-link">
                   <Link to='/about' className="text-purple news-readmore">
                   <span className="ms-0 me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 25 25"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="#fff"
                        d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z"
                      />
                    </svg>
                  </span>
                  BACK TO ABOUT PAGE
                </Link>
              </div>      
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutFounder;
